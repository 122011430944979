import React, { useEffect, useState } from "react";
import Banner from "../components/banner";
import useAPI from "../hooks/use-api";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
// import Swiper core and required modules
import SwiperCore, { Pagination } from "swiper";

// install Swiper modules
SwiperCore.use([Pagination]);

function Home() {
  const api = useAPI();
  const [partner, setPartner] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const partnerJSON = await api.getPartner();
      setPartner(partnerJSON);
      setLoading(false);
    }
    fetchData();
  });

  if (isLoading) {
    return <h1 className="text-2xl p-5 text-center">Loading...</h1>;
  }

  return (
    <div>
      {/* <div className="hidden md:block relative w-full">
        <div className="absolute banner-text1">
          <p>
            SeaInsure Broker <br />
            ซีอินชัวร์ โบรคเกอร์
          </p>
        </div>
        <div className="absolute banner-text2">
          <p>
            เพียบพร้อมไปด้วยทีมผู้เชี่ยวชาญ <br />
            ด้านการประกันภัย เป็นพันธมิตรกับประกันวินาศภัย
          </p>
        </div>
        <Banner
          className="object-cover"
          src={require("@/assets/img/banner_home.png").default}
          style={{ height: "720px", width: "100%" }}
        />
      </div> */}

      <div className="hidden md:block w-full">
        <Banner src={require('@/assets/img/desktop_banner_home.jpg').default} />
      </div>

      <div className="md:hidden w-full">
        <Banner
          className="object-fit"
          src={require('@/assets/img/mobile_banner_home.jpg').default}
        />
      </div>

      <div className="m-auto" style={{ maxWidth: '1260px' }}>
        <img
          alt="logo"
          src={require('@/assets/img/logo.png').default}
          className="m-auto md:mt-20 mt-10 px-10"
          id="history"
          loading="lazy"
        />

        <div className="m-auto text-left md:text-center py-8 px-5" style={{ fontSize: '16px' }}>
          <p>
            ซีอินชัวร์ โบรคเกอร์ เป็นส่วนหนึ่งของ Sea Group
            บริษัทชั้นนำระดับโลกด้านการให้บริการอินเทอร์เน็ตแพลตฟอร์มสำหรับผู้บริโภค
          </p>
          <br />
          <p className="hidden md:block">
            ซีอินชัวร์ โบรคเกอร์ เพียบพร้อมไปด้วยทีมผู้เชี่ยวชาญด้านการประกันภัย
            เรามุ่งมั่นที่จะช่วยเหลือลูกค้าในการจัดการความเสี่ยงภัยทางธุรกิจ ซีอินชัวร์ โบรคเกอร์
          </p>
          <p className="md:hidden">
            ซีอินชัวร์ โบรคเกอร์ <br />
            เพียบพร้อมไปด้วยทีมผู้เชี่ยวชาญด้านการประกันภัย
            เรามุ่งมั่นที่จะช่วยเหลือลูกค้าในการจัดการความเสี่ยงภัยทางธุรกิจ ซีอินชัวร์ โบรคเกอร์
          </p>
          <p>
            ให้บริการประกันวินาศภัยที่หลากหลายครอบคลุมทุกความเสี่ยงภัย
            และยังเป็นพันธมิตรกับบริษัทประกันวินาศภัยชั้นนำกว่า 20 แห่งในประเทศไทย
          </p>
          <p>
            ไม่ว่าคุณจะเป็นเจ้าของธุรกิจระดับประเทศหรือธุรกิจขนาดเล็ก ซีอินชัวร์
            โบรคเกอร์พร้อมที่จะช่วยเหลือคุณตลอดระยะเวลาประกันภัย
          </p>
        </div>
        <div className="hidden md:block m-auto text-center py-4 px-5 md:py-8">
          <h1>ทำไมต้องเลือก ซีอินชัวร์ โบรคเกอร์?</h1>
        </div>
        <div className="md:hidden m-auto text-center py-4 px-5 md:py-8">
          <h1>
            ทำไมต้องเลือก <br /> ซีอินชัวร์ โบรคเกอร์?
          </h1>
        </div>
        <div className="why-container gap-6 px-5">
          <div className="why-box flex-col p-6 ">
            <img
              alt="grow"
              loading="lazy"
              src={require('@/assets/img/icon/icon_grow.png').default}
            />
            <p className="text-center">มีพันธมิตรและเครือข่ายมากมาย</p>
            <p className="text-center">ที่พร้อมสนับสนุนธุรกิจให้ก้าวไกล</p>
          </div>
          <div className="why-box flex-col p-6">
            <img
              alt="product"
              loading="lazy"
              src={require('@/assets/img/icon/icon_product.png').default}
            />
            <p className="text-center">มีผลิตภัณฑ์ประกันภัยให้เลือกมากมาย</p>
            <p className="text-center">ครอบคลุมหลายโจทย์ธุรกิจ</p>
          </div>
          <div className="why-box flex-col p-6">
            <img
              alt="protext"
              loading="lazy"
              src={require('@/assets/img/icon/icon_chat.png').default}
            />
            <p className="text-center">มีพนักงานผู้เชี่ยวชาญ</p>
            <p className="text-center">พร้อมให้บริการและคำปรึกษา</p>
          </div>
          <div className="why-box flex-col p-6">
            <img
              alt="grow"
              loading="lazy"
              src={require('@/assets/img/icon/icon_protect.png').default}
            />
            <p className="text-center">
              ปลอดภัย <br />
              ได้รับใบอนุญาตจากสำนักงาน
              <br />
              คณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจ ประกันภัย (คปภ.)
            </p>
          </div>
        </div>
        <div className="m-auto text-center pt-20 pb-8" id="partner">
          <h1>บริษัทพันธมิตรชั้นนำ</h1>
        </div>
        <div className="py-6 px-5">
          <Swiper
            pagination={{
              bulletActiveClass: 'button-swiper-active',
              bulletClass: 'button-swiper',
              el: '.my-custom-pagination-div',
              clickable: true,
            }}
            // pagination={true}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="partner-container gap-6">
                {partner.map(title => {
                  const imgSrc = require(`@/assets/img/partner/${title.title}.png`).default

                  return (
                    <div className="partner-box flex items-center" key={title.title}>
                      <img
                        alt="partner"
                        className="m-auto object-contain p-3"
                        style={{ maxHeight: '100px' }}
                        loading="lazy"
                        src={ imgSrc }
                      />
                    </div>
                  )
                })}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default Home;
